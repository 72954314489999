import { APP_SOURCE_MAP, SALE_MAP, TXN_MAP } from 'types/constants';
const { LINE_ITEM_TYPE } = SALE_MAP;
const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/server/scsToIcnAdapter';
export const scsToIcnAdapter = (
  scsFetchInvoiceDataResponse,
  { token, realmId, intuit_tid },
  event
) => {
  try {
    const { companyInfo, sale } = scsFetchInvoiceDataResponse;
    const {
      companyName,
      language,
      region,
      sourceOffering,
      contactMethods,
      logo,
      subscriptionStatus,
    } = companyInfo;
    const {
      receivable,
      amount,
      txnDate,
      type,
      referenceNumber,
      id,
      entity,
      contact: { displayName, toEmails },
      tax,
      shipping,
      lines,
      freight,
      currencyInfo: { currency },
      paymentDetailMessage,
      paymentEnabledForLocale,
      description,
    } = sale;
    const {
      allowOnlineACHPayment,
      allowOnlineCreditCardPayment,
      allowPartialPayment,
      allowOnlinePayment,
      balance,
      dueDate,
    } = receivable;
    const { primaryEmail, primaryTelephone, webSite, addresses } = contactMethods[0];

    const shipmentAddress = {};
    const companyAddress = {};
    let actualId = id;
    if (!id) {
      actualId = entity && entity.globalId && entity.globalId.localId;
    }
    if (addresses && addresses.length && addresses[0]) {
      const { addressComponents } = addresses[0];
      if (addressComponents) {
        Object.keys(addressComponents).forEach((key) => {
          if (key === 'state') {
            companyAddress.stateOrRegion = addressComponents[key];
          } else {
            companyAddress[key] = addressComponents[key];
          }
        });
      }
    }

    if (shipping && shipping.shipAddress) {
      const {
        shipAddress: { addressComponents },
      } = shipping;
      if (addressComponents && Array.isArray(addressComponents)) {
        for (let shipAddressDetails of addressComponents) {
          shipmentAddress[`${shipAddressDetails.name}`] = shipAddressDetails.value;
        }
      }
    }

    // As of January 27 2021 Safari does not support the format DD-MM-YYY
    const formattedDueDate = typeof dueDate === 'string' ? dueDate.replace(/-/gi, '/') : null;
    const locale = language && region ? `${language}_${region}` : 'en_US';
    const contextData = {
      appSourceOffering: getAppSourceOffering(sourceOffering),
      balanceAmount: balance,
      companyEmail: primaryEmail ? primaryEmail.emailAddress : null,
      companyId: realmId,
      companyLanguage: language,
      companyLocale: locale,
      companyName,
      companyRegion: region || null,
      currency,
      amountCurrencySymbol: getCurrencySymbol(currency),
      balanceCurrencySymbol: getCurrencySymbol(currency),
      balanceCurrency: currency,
      achPaymentEnabled: allowOnlineACHPayment,
      creditCardPaymentEnabled: allowOnlineCreditCardPayment,
      currencySymbol: getCurrencySymbol(currency),
      customerName: displayName,
      lineItems: processLineItems(lines),
      discountAmount: lines ? calculateDiscountAmount(lines) : null,
      domainId: `INVOICE:${realmId}_${actualId}`,
      dueDate: formattedDueDate
        ? new Date(`${formattedDueDate}  UTC+00:00`).toISOString().split('T')[0]
        : null,
      einvoicingPaymentEnabled: true,
      invoiceAmount: amount,
      invoiceId: actualId,
      invoiceNum: referenceNumber,
      txnDate: txnDate ? txnDate : null,
      token,
      taxAmount: tax ? tax.totalTaxAmount : null,
      partialPaymentEnabled: allowPartialPayment,
      paymentEnabled: allowOnlinePayment,
      paymentEnabledForLocale,
      shippingAmount: lines ? calculateShippingAmount(lines) : null,
      shipPostalCode:
        typeof shipmentAddress.postalCode !== 'undefined' ? shipmentAddress.postalCode : null,
      ticket: null,
      companyReadOnly: false, // hardcoded to align with ICN structure, not used in code
      numberFormatString: 'en', // hardcoded to align with ICN structure, not used in code
      customerJobName: null, // hardcoded to align with ICN structure, not used in code
      customerJobId: null, // hardcoded to align with ICN structure, not used in code
      contextId: `INVOICE:${realmId}_${actualId}`, // hardcoded to align with ICN structure, not used in code
      amountCurrency: 'USD', // hardcoded to align with ICN structure, not used in code
    };
    if (typeof description !== 'undefined') {
      contextData.description = description;
    }
    contextData.freightAmount = calculateFreightAmount(freight);
    if (toEmails && toEmails.length && toEmails[0]) {
      contextData.recipientEmail = toEmails[0];
    }
    if (typeof type !== 'undefined') {
      contextData.transactionType = type;
    }
    if (logo) {
      contextData.companyLogoUrl = logo;
    }
    if (primaryTelephone && primaryTelephone.number) {
      contextData.companyPhone = primaryTelephone.number;
    }
    if (Object.keys(companyAddress).length > 0) {
      contextData.companyAddress = companyAddress;
    }
    if (webSite) {
      contextData.companyWebAddr = webSite;
    }
    if (paymentDetailMessage) {
      contextData.paymentDetailsMessage = paymentDetailMessage;
    }
    if (subscriptionStatus) {
      contextData.subscriptionStatus = subscriptionStatus;
    }
    return {
      contextData,
    };
  } catch (error) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event,
      action: 'scsToIcnAdapter',
      activityInfo: {
        status: 'error',
        intuitTid: intuit_tid,
      },
      error: {
        stack: error.stack,
        message: error.message,
      },
    });
    throw error;
  }
};

const getAppSourceOffering = (sourceOffering) => {
  switch (sourceOffering) {
    case TXN_MAP.OFFERING_TYPES.QBDT:
      return APP_SOURCE_MAP.QB_DESKTOP_APP_SOURCE_OFFERING;
    case TXN_MAP.OFFERING_TYPES.QBSE:
    case TXN_MAP.OFFERING_TYPES.STS:
      return APP_SOURCE_MAP.QBSE_APP_SOURCE_OFFERING;
    case TXN_MAP.OFFERING_TYPES.QBO:
    default:
      return APP_SOURCE_MAP.QBO_APP_SOURCE_OFFERING;
  }
};

/**
 * @param {string} currency
 * @returns {string}
 */
const getCurrencySymbol = (currency) => {
  if (!currency) {
    return '$';
  }

  // adapted from data in https://www.npmjs.com/package/currency-symbol-map
  let currency_symbols = {
    AED: 'د.إ',
    AFN: '؋',
    ALL: 'L',
    AMD: '֏',
    ANG: 'ƒ',
    AOA: 'Kz',
    ARS: '$',
    AUD: '$',
    AWG: 'ƒ',
    AZN: '₼',
    BAM: 'KM',
    BBD: '$',
    BDT: '৳',
    BGN: 'лв',
    BHD: '.د.ب',
    BIF: 'FBu',
    BMD: '$',
    BND: '$',
    BOB: '$b',
    BOV: 'BOV',
    BRL: 'R$',
    BSD: '$',
    BTC: '₿',
    BTN: 'Nu.',
    BWP: 'P',
    BYN: 'Br',
    BYR: 'Br',
    BZD: 'BZ$',
    CAD: '$',
    CDF: 'FC',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: '$',
    CNY: '¥',
    COP: '$',
    COU: 'COU',
    CRC: '₡',
    CUC: '$',
    CUP: '₱',
    CVE: '$',
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'kr',
    DOP: 'RD$',
    DZD: 'دج',
    EEK: 'kr',
    EGP: '£',
    ERN: 'Nfk',
    ETB: 'Br',
    ETH: 'Ξ',
    EUR: '€',
    FJD: '$',
    FKP: '£',
    GBP: '£',
    GEL: '₾',
    GGP: '£',
    GHC: '₵',
    GHS: 'GH₵',
    GIP: '£',
    GMD: 'D',
    GNF: 'FG',
    GTQ: 'Q',
    GYD: '$',
    HKD: '$',
    HNL: 'L',
    HRK: 'kn',
    HTG: 'G',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    IMP: '£',
    INR: '₹',
    IQD: 'ع.د',
    IRR: '﷼',
    ISK: 'kr',
    JEP: '£',
    JMD: 'J$',
    JOD: 'JD',
    JPY: '¥',
    KES: 'KSh',
    KGS: 'лв',
    KHR: '៛',
    KMF: 'CF',
    KPW: '₩',
    KRW: '₩',
    KWD: 'KD',
    KYD: '$',
    KZT: '₸',
    LAK: '₭',
    LBP: '£',
    LKR: '₨',
    LRD: '$',
    LSL: 'M',
    LTC: 'Ł',
    LTL: 'Lt',
    LVL: 'Ls',
    LYD: 'LD',
    MAD: 'MAD',
    MDL: 'lei',
    MGA: 'Ar',
    MKD: 'ден',
    MMK: 'K',
    MNT: '₮',
    MOP: 'MOP$',
    MRO: 'UM',
    MRU: 'UM',
    MUR: '₨',
    MVR: 'Rf',
    MWK: 'MK',
    MXN: '$',
    MXV: 'MXV',
    MYR: 'RM',
    MZN: 'MT',
    NAD: '$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'kr',
    NPR: '₨',
    NZD: '$',
    OMR: '﷼',
    PAB: 'B/.',
    PEN: 'S/.',
    PGK: 'K',
    PHP: '₱',
    PKR: '₨',
    PLN: 'zł',
    PYG: '₲',
    QAR: '﷼',
    RMB: '￥',
    RON: 'lei',
    RSD: 'Дин.',
    RUB: '₽',
    RWF: 'R₣',
    SAR: '﷼',
    SBD: '$',
    SCR: '₨',
    SDG: 'ج.س.',
    SEK: 'kr',
    SGD: 'S$',
    SHP: '£',
    SLL: 'Le',
    SOS: 'S',
    SRD: '$',
    SSP: '£',
    STD: 'Db',
    STN: 'Db',
    SVC: '$',
    SYP: '£',
    SZL: 'E',
    THB: '฿',
    TJS: 'SM',
    TMT: 'T',
    TND: 'د.ت',
    TOP: 'T$',
    TRL: '₤',
    TRY: '₺',
    TTD: 'TT$',
    TVD: '$',
    TWD: 'NT$',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    USD: '$',
    UYI: 'UYI',
    UYU: '$U',
    UYW: 'UYW',
    UZS: 'лв',
    VEF: 'Bs',
    VES: 'Bs.S',
    VND: '₫',
    VUV: 'VT',
    WST: 'WS$',
    XAF: 'FCFA',
    XBT: 'Ƀ',
    XCD: '$',
    XOF: 'CFA',
    XPF: '₣',
    XSU: 'Sucre',
    XUA: 'XUA',
    YER: '﷼',
    ZAR: 'R',
    ZMW: 'ZK',
    ZWD: 'Z$',
    ZWL: '$',
  };

  if (currency_symbols[currency]) {
    return currency_symbols[currency];
  }
  return '$';
};

export const calculateDiscountAmount = (lineItems) => {
  const discountLineItem = (lineItems || []).find(
    (lineItem) => lineItem?.type === LINE_ITEM_TYPE.DISCOUNT_LINE_DETAIL
  );
  if (discountLineItem && discountLineItem.amount) {
    return discountLineItem.amount;
  }
  return 0;
};

export const calculateFreightAmount = (freight) => {
  return freight ? freight : 0;
};

export const calculateSubTotalAmount = (lineItems) => {
  const subTotalAmount = (lineItems || []).find(
    (lineItem) => lineItem?.type === LINE_ITEM_TYPE.SUBTOTAL_ITEM_LINE_DETAIL
  );
  if (subTotalAmount && subTotalAmount.amount) {
    return subTotalAmount.amount;
  }
  return null;
};

export const calculateShippingAmount = (lineItems) => {
  const shippingAmount = (lineItems || []).find(
    (lineItem) =>
      lineItem &&
      lineItem?.type === LINE_ITEM_TYPE.SALES_ITEM_LINE_DETAIL &&
      lineItem?.item?.id?.globalId?.localId === LINE_ITEM_TYPE.SHIPPING_ITEM_ID
  );
  if (shippingAmount && shippingAmount.amount) {
    return shippingAmount.amount;
  }
  return null;
};

export const calculateSummaryAmountLines = (lineItems) => {
  return {
    discountAmount: calculateDiscountAmount(lineItems),
    subTotalAmount: calculateSubTotalAmount(lineItems),
    shippingAmount: calculateShippingAmount(lineItems),
  };
};

export const flattenLineItemAdapter = (lineItem, isAddL3Fields = false) => {
  const { item, rate, type, amount, description, quantity, unitOfMeasure, itemTaxRate, taxAmount } =
    lineItem;
  let flattenedLine = {
    description,
    amount,
    productCode:
      type === LINE_ITEM_TYPE.SALES_ITEM_LINE_DETAIL && item?.id ? item?.id?.globalId?.localId : '',
    unitPrice: type === LINE_ITEM_TYPE.SALES_ITEM_LINE_DETAIL && rate ? rate.moneyValue : null,
    quantity: type === LINE_ITEM_TYPE.SALES_ITEM_LINE_DETAIL ? quantity : 0.0,
    unitOfMeasure: unitOfMeasure ? unitOfMeasure : null,
  };

  if (isAddL3Fields) {
    flattenedLine = {
      ...flattenedLine,
      lineType: type,
      itemTaxRate: itemTaxRate || null,
      taxAmount: taxAmount || null,
      quantity, // overriding the calculated quantity because L3 needs the original value
    };
  }

  return flattenedLine;
};

export const groupLineItems = (lineItems) => {
  let processedLineItems = [];
  // case of payment req
  if (!lineItems) {
    return processedLineItems;
  }

  let bundleLUT = new Map();
  // Compute total group/bundle line items and add regular line items to processedLineItems collection
  for (let lineItem of lineItems) {
    let groupId,
      amount,
      bundlePair = null;
    // Regular line items including discount line items and line items with shipping information
    if (
      (lineItem?.type === LINE_ITEM_TYPE.SALES_ITEM_LINE_DETAIL && lineItem?.id) ||
      lineItem?.type === LINE_ITEM_TYPE.DISCOUNT_LINE_DETAIL ||
      (lineItem?.type === LINE_ITEM_TYPE.SALES_ITEM_LINE_DETAIL && lineItem?.bundleDetails)
    ) {
      processedLineItems.push(lineItem);

      // Group/Bundle line items
    } else if (lineItem?.type === LINE_ITEM_TYPE.GROUP_LINE_DETAIL) {
      const {
        id: {
          globalId: { localId },
        },
      } = lineItem;
      groupId = localId;
      bundlePair = bundleLUT.get(groupId);
      amount = 0;

      if (bundlePair && bundlePair.amountValue) {
        amount = bundlePair.amountValue;
      }

      bundleLUT.set(groupId, { item: lineItem, amountValue: amount });
      // Line items within a group/bundle
    } else if (!lineItem?.type && lineItem?.groupId) {
      groupId = lineItem?.groupId;
      amount = lineItem?.amount ? lineItem.amount : 0;
      let bundleLineItem = null;
      bundlePair = bundleLUT.get(groupId);

      if (bundlePair && bundlePair?.amountValue) {
        amount = amount.add(bundlePair.amountValue);
      }

      if (bundlePair && bundlePair?.item) {
        bundleLineItem = bundlePair.item;
      }

      bundleLUT.set(groupId, { item: bundleLineItem, amountValue: amount });
    }
  }

  // Add bundle.group line items to processedLineItems collection
  for (let bundlePair of bundleLUT.values()) {
    let bundleLineItem = bundlePair.item;
    let amount = bundlePair.amountValue;

    if (bundleLineItem) {
      if (amount) {
        bundleLineItem.amount = amount;
      }

      processedLineItems.push(bundleLineItem);
    }
  }
  return processedLineItems;
};

const processLineItems = (lineItems) => {
  return groupLineItems(lineItems)?.map((line) => flattenLineItemAdapter(line));
};

export { getCurrencySymbol, processLineItems, getAppSourceOffering };
