const { HttpStatusCode } = require('axios');

export const TXN_MAP = {
  OFFERING_TYPES: {
    QBO: 'QBO',
    QBSE: 'QBSE',
    STS: 'STS',
    QBDT: 'QBDT',
    QBMONEY: 'QBMONEY',
  } as const,
  RECEIPT_TYPES: {
    INVOICE_RECEIPT: 'invoiceReceipt',
    DEPOSIT_RECEIPT: 'depositReceipt',
    PAYMENT_REQ_RECEIPT: 'paymentReqReceipt',
  } as const,
  STATUS: {
    IN_PROGRESS: 'inProgress',
    SUCCESS: 'success',
    ERROR: 'error',
    DECLINED: 'DECLINED',
  } as const,
  TYPES: {
    INVOICE: 'INVOICE',
    PAYMENT_REQUEST: 'PAYMENT_REQUEST',
    ESTIMATE: 'ESTIMATE',
    SUBSCRIPTION: 'SUBSCRIPTION',
  } as const,
  SUB_TYPES: {
    PAYMENT_LINK_MULTIPLE: 'PAYMENT_LINK_MULTIPLE',
    PAYMENT_LINK_SINGLE: 'PAYMENT_LINK_SINGLE',
    NOT_PAYMENT_LINK_MULTIPLE: 'NOT_PAYMENT_LINK_MULTIPLE',
  } as const,
} as const;

export const SALE_MAP = {
  STATUS: {
    ACCEPTED: 'ACCEPTED',
    CLOSED: 'CLOSED',
    REJECTED: 'REJECTED',
    PENDING: 'PENDING',
  } as const,
  LINE_ITEM_TYPE: {
    GROUP_LINE_DETAIL: 'GroupLineDetail',
    SALES_ITEM_LINE_DETAIL: 'SalesItemLineDetail',
    SUBTOTAL_ITEM_LINE_DETAIL: 'SubTotalLineDetail',
    DISCOUNT_LINE_DETAIL: 'DiscountLineDetail',
    SHIPPING_ITEM_ID: 'SHIPPING_ITEM_ID',
  } as const,
  SUBSCRIPTION_STATUS_TYPES: {
    NEW: 'NEW',
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    PAUSED: 'PAUSED',
    INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
    DECLINED: 'DECLINED',
    CANCELLED: 'CANCELLED',
    ERROR: 'ERROR',
  } as const,
};

export const PAYMENT_MAP = {
  REJECT_TYPES: {
    MISSING_CONSENT: 'MISSING_CONSENT',
  } as const,
  AMOUNT_CONFIG: {
    MAX_ALLOWED_AMOUNT: 100000.0,
    MAX_ALLOWED_AMOUNT_CC_DC_ACH: 500000.0,
    MIN_PAYMENT: 1.0,
    MULTIPLE_FACTOR: 3,
  },
};

export const WALLET_MAP = {
  FETCH_STATUS: {
    FETCHING: 'fetching',
    FETCH_SUCCESS: 'fetchSuccess',
    FETCH_FAILED: 'fetchFailed',
  } as const,
};

export const APP_SOURCE_MAP = {
  QB_DESKTOP_APP_SOURCE_OFFERING: 'Intuit.sbg.quickbooks-win-us',
  QBO_APP_SOURCE_OFFERING: 'Intuit.sbe.salsa.platform',
  QBSE_APP_SOURCE_OFFERING: 'Intuit.smallbusiness.qbse',
} as const;

export type TXN_STATUSES = typeof TXN_MAP.STATUS[keyof typeof TXN_MAP.STATUS];

export type ScheduleIdType = 10 | 20;
export type ScheduleType = 'SCHEDULE_PAY' | 'AUTO_PAY';
export type ScheduleStats = 'DECLINED' | 'ERROR' | 'PROCESSED';

export type CardType = 'VISA' | 'MC' | 'DISC' | 'AMEX' | 'diners';

export type PaymentMethod =
  | 'credit_karma'
  | 'dc'
  | 'cc'
  | 'dc,cc'
  | 'bank'
  | 'ap'
  | 'pp'
  | 'paypal'
  | 'paypal_ppaam'
  | 'venmo'
  | 'nanopay'
  | 'eft'
  | 'amex'
  | 'PayPalCommerce'
  | 'paypal_ppaam paylater';

export type PaymentMethodSubType = 'paylater';

export type PayPalProcessor = 'PPAAM' | 'APPCONNECT' | 'UNKNOWN';

export const DateFormat_Long = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
} as const;

export enum LOG_FLINK_STEP {
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
  REDIRECT = 'REDIRECT',
}

export enum FREQ {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export enum CURRENCY {
  USD = 'USD',
}

export enum COUNTRY {
  US = 'US',
  CA = 'CA',
}

export enum NODE_NETWORK_ERRORS {
  ECONNRESET = 'ECONNRESET',
  ENOTFOUND = 'ENOTFOUND',
  ETIMEDOUT = 'ETIMEDOUT',
  ECONNREFUSED = 'ECONNREFUSED',
  ERRADDRINUSE = 'ERRADDRINUSE',
  EADDRNOTAVAIL = 'EADDRNOTAVAIL',
  ECONNABORTED = 'ECONNABORTED',
  EHOSTUNREACH = 'EHOSTUNREACH',
  EAI_AGAIN = 'EAI_AGAIN',
  ENOENT = 'ENOENT',
  EISDIR = 'EISDIR',
  ENOTDIR = 'ENOTDIR',
  EACCES = 'EACCES',
  EEXIST = 'EEXIST',
  EPERM = 'EPERM',
}

export enum PAYMENT_ERROR_TYPES {
  GENERAL_PAYMENT_ERROR = 'GENERAL_PAYMENT_ERROR',
  RISK_PAYMENT_ERROR = 'RISK_PAYMENT_ERROR',
  MISSING_ZIPCODE_ERROR = 'ZIPCODE_PAYMENT_ERROR',
  TIMEOUT = 'TIMEOUT_ERROR',
  APPCONNECT = 'PAYPAL_APPCONNECT_ERROR',
  SALE_VALIDATION = 'SALE_VALIDATION',
  DECLINED = 'DECLINED',
}

export enum CP_ERROR_CODES {
  RISK_PAYMENT_ERROR = 'CP-0002',
  SALE_VALIDATION = 'CP-0003',
  MISSING_ZIPCODE_ERROR = 'CP-0005',
}

export const HTTP_STATUS_CODES = HttpStatusCode;
