import Logger from 'reporting/Logger';
import { TXN_MAP } from 'types/constants';

const isServer = () => typeof window === 'undefined' || window.isServer === true;
export const isSalesCheckoutToken = (token) =>
  typeof token === 'string' && token.indexOf('scs-') === 0;

export const isPaymentRequest = (type) => type === TXN_MAP.TYPES.PAYMENT_REQUEST;

export const isEstimate = (type) => type === TXN_MAP.TYPES.ESTIMATE;

export const isOneToManyPaymentRequest = (type, subType) =>
  isPaymentRequest(type) && subType === TXN_MAP.SUB_TYPES.PAYMENT_LINK_MULTIPLE;

export const isInvoice = (type) => type === TXN_MAP.TYPES.INVOICE;

export const getDateFromString = (value) => {
  //  Safari & IE browsers do not support the date format “yyyy-mm-dd”
  const fixDateForAllBrowsers = value.replace(/-/g, '/');
  return new Date(fixDateForAllBrowsers);
};

/**
 * Removes the bank payment payment method when `enabledPaymentMethods` has `nanopay' and (invoice is partially paid or sale `amount` < `minimumAmountForBankPayment`)
 * Also the credit card method should ne available for fallback. When cc is not available then bank payments method (nanopay) is not removed. The payor will see the
 * UI where they will be asked to contact the merchant on how to pay invoice
 * @param {*} enabledPaymentMethods
 * @param {*} isBankPaymentsEnabled
 * @param {*} isPartiallyPaid
 * @param {*} amount
 * @returns enabledPaymentMethods
 */
const enabledPaymentMethodsForCABankPayments = (
  enabledPaymentMethods,
  config,
  isBankPaymentsEnabled,
  isPartiallyPaid,
  amount
) => {
  try {
    const { nanopay: { minimumAmountForBankPayment = 1 } = {} } = config;
    if (
      enabledPaymentMethods.indexOf('nanopay') > -1 &&
      isBankPaymentsEnabled &&
      (isPartiallyPaid || amount < minimumAmountForBankPayment)
    ) {
      // only remove bank payments (nanopay) when cc is available for the invoice.
      if (enabledPaymentMethods.indexOf('cc') > -1) {
        return enabledPaymentMethods.filter((pm) => pm !== 'nanopay');
      }
    }
  } catch (error) {
    Logger.error({
      EventName: 'enabledPaymentMethodsForCABankPayments',
      EventMessage: 'error',
      enabledPaymentMethods,
      config,
      isBankPaymentsEnabled,
      isPartiallyPaid,
      amount,
    });
  }
  return enabledPaymentMethods;
};

const encrypt = (salt, text) => {
  if (typeof salt !== 'string' || typeof text !== 'string') {
    throw new Error('encrypt: salt and text must be strings');
  }
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n) => {
    const str = '0' + Number(n).toString(16);
    return str.substring(str.length - 2, str.length);
  };
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

const decrypt = (salt, encoded) => {
  if (typeof salt !== 'string' || typeof encoded !== 'string') {
    throw new Error('decrypt: salt and text must be strings');
  }
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join('');
};

const flipObject = (data) => {
  return Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));
};

export function mergeDeep(obj1, obj2) {
  // Handle edge cases
  if (!obj1) return obj2;
  if (!obj2) return obj1;
  // Create a new object to store the merged properties
  const result = {};
  // Merge properties from obj1
  for (const key in obj1) {
    if (
      typeof obj1[key] === 'object' &&
      typeof obj2[key] === 'object' &&
      !Array.isArray(obj2[key])
    ) {
      result[key] = mergeDeep(obj1[key], obj2[key]);
    } else {
      result[key] = obj1[key];
    }
  }
  // Merge properties from obj2, overwriting obj1 properties
  for (const key in obj2) {
    if (
      typeof obj2[key] === 'object' &&
      typeof obj1[key] === 'object' &&
      !Array.isArray(obj2[key])
    ) {
      result[key] = mergeDeep(obj1[key], obj2[key]);
    } else {
      result[key] = obj2[key];
    }
  }
  return result;
}

function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
};

const checkIfFitsPattern = ({ pattern, value = '', debug }) => {
  if (value !== '' && pattern) {
    try {
      const reg = new RegExp(pattern.replace('*', '+'), 'g');
      const result = reg.exec(value);
      return result !== null && result[0] === value;
    } catch (e) {
      debug(e);
    }
  }
  return true;
};

const formatNumberWithCommaAndCurrency = ({ value, currency }) => {
  const rawValue = String(value).replace(/,/g, '');
  const formattedNumber = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // if its not a $, add a space between the number to the currency
  return currency?.length > 1 ? `${currency} ${formattedNumber}` : `${currency}${formattedNumber}`;
};

export {
  enabledPaymentMethodsForCABankPayments,
  encrypt,
  decrypt,
  flipObject,
  isServer,
  deepClone,
  isNumeric,
  checkIfFitsPattern,
  formatNumberWithCommaAndCurrency,
};
